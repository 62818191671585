.work-section-container {

    color: aliceblue;
    margin-top: 10%;
    margin-left: 10%;
    display: flex;
    margin-bottom: 1%;
}

.work-section-container h1{
    font-weight: 300;
    display: inline-block;
    color: #ccd6f6;
    margin: 0;
}

.work-header-container {
    margin-left: 10%;
    font-weight: 300;
    display: inline-block;
    color: #ccd6f6;
    
}

.work-section-container-optimized {
    display: none;
}

.work-header-container p{
    font-weight: 150;
    font-size: 22px;
    
}

.images-container {
    margin-right: 12%;
    display: flex;
    justify-content: space-between;
    margin-left: 12%;

}

.horizontal-line {
    background-color: antiquewhite;
    width: 200px;
    height: 1px;
    margin: 0;
    margin-left: 20px;
    margin-top: 20px;
}

.animation-slide-up {
    opacity: 0;
    transform: translateY(50px);
    transition: all 1s ease-out;
}

.scroll-animation {
    opacity: 1;
    transform: translateY(0);
}

/*  Card Section Goes Here  */


.work-container-title {
    margin-bottom: 150px;
    margin-top: 150px;
}

.work-container-title h1{
    background-image: linear-gradient(240deg,#fff,#5f49d7 50%);
    font-size: 42px;
    text-transform: uppercase;
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.cards-container {
    display: grid;
    /* flex-direction: row;
    flex-wrap: wrap; */
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 30px;
    margin-top: 30px;
    margin-left: 10%;
    align-items: center;
}

.card-container {
    background-color: #000000;
    height: 300px;
    width: 300px;
    border-radius: 32px;
    box-shadow: 0.5px 0.5px 20px rgb(147, 147, 147);
    transition: 0.2s ease-in-out;
    display: flex;
    align-items: center;
}

.card-container p{
    color: #9e9e9e;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
}

.card-container:hover{
    /* transform: translateY(-3px); */
    transform: translateY(-10px)
}

@media screen and (max-width: 479px) {
    .work-section-container {
        display: none;
    }

    .work-section-container-optimized{
        color: aliceblue;
        margin-top: 10%;
        margin-left: 10%;
        display: flex;
        margin-bottom: 1%;
    }

    .work-section-container-optimized h1{
        font-weight: 300;
        display: inline-block;
        color: #ccd6f6;
        margin: 0;
    }

    .horizontal-line-optimized{
        background-color: antiquewhite;
        width: 200px;
        height: 1px;
        margin: 0;
        margin-left: 20px;
        margin-top: 20px;
    }

    .images-container {
        margin-right: 12%;
        display: flex;
        justify-content: space-between;
        margin-left: 12%;
        flex-direction: column;
        margin-bottom: 50px;
    
    }

    .cards-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 2px;
        align-items: center;
        margin-right: 30px;

    }

    .card-container {
        background-color: #000000;
        height: 300px;
        width: 300px;
        border-radius: 32px;
        box-shadow: 0.5px 0.5px 20px rgb(147, 147, 147);
        transition: 0.2s ease-in-out;
        display: flex;
        align-items: center;
        margin-bottom: 60px;
    }

    .work-header-container p{
        font-weight: 300;
        font-size: 22px;
        
    }

    .work-container-title h1{
        background-image: linear-gradient(240deg,#fff,#5f49d7 50%);
        font-size: 32px;
        text-transform: uppercase;
        text-align: center;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

}