a{
    text-decoration: none;
    color: inherit;

}

.button-container-optimized{
    display: none;
}

.contact-section-container {
    margin-top: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-container{
    display: flex;
    margin-left: 44%;
}


.logo-button:hover{
opacity: 0.7;
transition: 0.3s ease-out;
}

.logo-button {
display: flex;
align-items: center;
justify-content: center;
gap: 8px;
padding: 20px 35px;
background-color: #5f49d7;
text-align: center;
border: none;
border-radius: 12px;
cursor: pointer;
margin-bottom: 50px;
}

.mail-img {
width: 24px;
height: 24px;
filter: brightness(0.8)

}

.button-text {
font-size: larger;
font-weight: 300;
}


.socials-container {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: left;

}

.socials-img {
    height: 36px;
    width: 36px;
    margin: 16px;

}
.socials-img:hover{
    opacity: 0.7;
}

.vertical-line {
    margin-left: 35px;
    width: 1px;
    height: 100px; /* Adjust the height of the line as needed */
    background-color: aliceblue;
}

@media screen and (max-width: 479px) {

    .contact-section-container{
        display: none
    }
    .button-container-optimized{
        display: flex;
        text-align: center;
        margin: 150px 120px;
    }

}
